<template>
  <div class="under-maintenance">
    <h1>网站升级维护中......</h1>
    【提示】如有相关业务需要，请联系有关部门，给您带来的不便请谅解！
  </div>
</template>

<script>
export default {
  name: 'under-maintenance',
  data () {
    return {
      // key: value
    }
  },
}
</script>

<style lang="scss" scoped>
.under-maintenance {
  padding: 40px;
  margin: 20px auto;
}
</style>