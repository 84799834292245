import router from './router'
router.beforeEach((to, from, next) => {
  // if (to.path === from.path) {
  //   next(false)
  // } else {
  //   localStorage.setItem('event_id', JSON.stringify(to.query.event_id));
  //   next()
  // }
  localStorage.setItem('event_id', JSON.stringify(to.query.event_id));
  next()

})

