<template>
  <div class="swiper-container banner-swiper">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="it in swiperList" :key="it.time">
        <template v-if="it.external_url">
          <a :href="$Base64.decode(it.external_url)" rel="noopener noreferrer">
            <el-image :src="baseURL + it.image" style="width: 100%; height: 100%;"></el-image>
          </a>
        </template>
        <template v-else>
          <el-image :src="baseURL + it.image" style="width: 100%;"></el-image>
        </template>
      </div>
    </div>
    <!-- 如果需要分页器 -->
    <div class="swiper-pagination"></div>

    <!-- 如果需要导航按钮 -->
    <!-- <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div> -->

  </div>
</template>
 
<script>
import Swiper from "swiper";

export default {
  name: 'bh-carousel',
  props: ['swiperList'],
  data () {
    return {
      baseURL: process.env.VUE_APP_BASE_API,
    };
  },
  mounted () {
    new Swiper(".banner-swiper", {
      // loop: true, // 循环模式选项
      effect: 'fade',
      //自动播放
      autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: false
      },
      // 如果需要分页器
      pagination: {
        el: ".swiper-pagination",
        // bulletClass : 'my-bullet',//需设置.my-bullet样式
        clickable: true,
      },
      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      // 延迟加载
      lazy: {
        loadPrevNext: true
      },
      observer: true, //修改swiper自己或子元素时，自动初始化swiper
      observeParents: true //修改swiper的父元素时，自动初始化swiper
    });
  },
  methods: {

  },
};
</script>
 
<style scoped>
swiper-container,
swiper-slide {
  height: 100%;
}
</style>