import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
  // {
  //   path: '/',
  //   redirect: '',
  // },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/isShowPoster.vue')
  },
  {
    path: '/detail/:id', // 通用移动端二级页面
    name: 'detail',
    component: () => import('../views/detail/index.vue')
  }, {
    path: '/newsList/:id', // 移动端新闻列表
    name: 'newsList',
    component: () => import('../views/newsList/index.vue')
  }, {
    path: '/newsdetail', // 移动端新闻--详情
    name: 'newsdetail',
    component: () => import('../views/newsList/detail.vue')
  },

  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
  {
    path: '/404',
    component: () => import('../views/404'),
    hidden: true
  },
  { path: '*', redirect: '/404', hidden: true }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
