
import axios from 'axios'
import store from '@/store'
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // baseURL: "http://192.168.3.34:8085/",
})
service.interceptors.request.use(
  config => {
    if (store.getters.token) {
      config.headers['token'] = getToken()
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
service.interceptors.response.use(
  response => {
    const res = response.data;
    if (res.code != 1) {
      return res
    } else {
      return res
    }
  },
  error => {
    return Promise.reject(error)
  }
)

export default service
