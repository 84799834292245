
const app = {
  state: {
    menuStatus: false, // pc端时菜单初始化展示，移动端初始化时隐藏
    isClose: false
  },
  mutations: {
    OPEN_MENU: (state, device) => {
      state.menuStatus = device

    },
    SET_IS_CLOSE: (state, isClose) => {
      state.isClose = isClose
    }
  },
  actions: {
    OpenMenu ({ commit, state }, data) {
      commit('OPEN_MENU', data)
    },
    isCollapse ({ commit, state }, data) {
      commit('SET_IS_CLOSE', !state.isClose)
    },
    // 还差调用接口
  }
}


export default app
