import request from '@/utils/request'

/** 移动端 */
// 获取网站配置接口
export function getMobileConfig (params) {
  return request({
    url: '/homeapi/Microsite/getMobileConfig',
    method: 'get',
    params
  })
}

// 获取栏目列表
export function getColumnList (params) {
  return request({
    url: '/homeapi/Microsite/getColumnList',
    method: 'get',
    params
  })
}
// 获取栏目详情
export function getColumnInfo (params) {
  return request({
    url: '/homeapi/Microsite/getColumnInfo',
    method: 'get',
    params
  })
}
// 获取新闻详情
export function getNewsInfo (params) {
  return request({
    url: '/adminapi/Website/getNewsInfo',
    method: 'get',
    params
  })
}


/** PC端 */
// 获取网站配置接口
export function getPcConfig (params) {
  return request({
    url: '/homeapi/Microsite/getPcConfig',
    method: 'get',
    params
  })
}

// 获取栏目列表
export function getPcColumnList (params) {
  return request({
    url: '/homeapi/Microsite/getPcColumnList',
    method: 'get',
    params
  })
}
// 获取栏目详情
export function getPcColumnInfo (params) {
  return request({
    url: '/homeapi/Microsite/getPcColumnInfo',
    method: 'get',
    params
  })
}
// 获取新闻详情
export function getPcNewsInfo (params) {
  return request({
    url: '/adminapi/Website/getPcNewsInfo',
    method: 'get',
    params
  })
}
