<template>
  <div id="app">
    <under-maintenance v-if="web_switch && !isDEV" />
    <template v-else>
      <div class="app-window" v-if="$isPC && web_pc_config.window_status == 2 && !close">
        <i class="el-icon-circle-close app-close" @click.stop="close = true"></i>
        <el-image @click="clickWindowImg(window_url)" style="width: 100%; height: 100%" :src="baseURL + web_pc_config.window_image" fit="fit"></el-image>
      </div>
      <BHCarousel :swiperList="carousel_img" v-if="$isPC"></BHCarousel>
      <!-- <keep-alive include="home"> -->
      <router-view :key="$route.path" />
      <!-- </keep-alive> -->
    </template>
  </div>
</template>

<script>
import Vue from 'vue'
import wx from "weixin-js-sdk";
import axios from 'axios'
import { getMobileConfig, getPcConfig } from "@/api/index";
import UnderMaintenance from "@/components/under-maintenance.vue";
import BHCarousel from "@/components/bh-carousel";

export default {
  name: 'app',
  components: {
    UnderMaintenance, BHCarousel
  },
  data () {
    return {
      baseURL: process.env.VUE_APP_BASE_API,
      isDEV: encodeURI(window.location.href.split("#")[0]) === 'http://localhost:8080/',
      event_id: undefined,
      web_switch: false, // 是否是在维护中
      ad_switch: 1, // 是否开启广告
      close: false, // false 是开，true 是关
      allConfigs: { web_config: {} },
    }
  },
  provide () {
    return {
      allConfigs: () => this.allConfigs
    }
  },
  computed: {
    carousel_img () {
      if (this.$isPC) {
        const images = this.allConfigs.web_pc_config?.carousel_img || '[]'
        return JSON.parse(images)
      } else return ''
    },
    window_url () {
      if (this.$isPC) {
        return this.$Base64.decode(this.allConfigs.web_pc_config.window_url)
      } else return ''
    },
    web_pc_config () {
      if (this.$isPC) {
        return this.allConfigs.web_pc_config || { window_status: 1, }
      } else return { window_status: 1, }
    },
  },
  created () {
    this.event_id = JSON.parse(localStorage.getItem('event_id'));
    if (this.$isPC) {
      this.getPcConfig()
    } else {
      this.getMobileConfig()
    }
  },
  methods: {
    clickWindowImg (url) {
      if (!url) return;
      window.open(url, '_blank')
    },
    // 获取移动端网站配置
    async getMobileConfig () {
      try {
        const res = await getMobileConfig({ event_id: this.event_id })
        const { ad_switch } = res.data?.web_mobile_config
        this.allConfigs = res.data;
        // 广告
        this.ad_switch = ad_switch;

        this.initWebConfig(res.data?.web_config)
        this.$nextTick(() => {
          if (!this.isDEV) {
            this.getShareInfo(res.data?.web_config);
          }
        })
      } catch (error) {
        console.log(error);
      }

    },
    // 获取PC端网站配置
    async getPcConfig () {
      try {
        const res = await getPcConfig({ event_id: this.event_id })
        this.allConfigs = res.data;
        this.initWebConfig(res.data?.web_config,)
      } catch (error) {
        console.log(error);
      }

    },
    // 初始化网站配置
    initWebConfig (web_config = {},) {
      const { web_switch, web_icon, web_title, } = web_config
      // 网站是否启用
      // this.web_switch = web_switch == 1 ? true : false;
      if ((web_switch == 2 && this.$isPC) || web_switch == 1) {
        // Vue.prototype.$isPC = false
        this.web_switch = true
      } else {
        this.web_switch = false
        // Vue.prototype.$isPC = window.screen.width > 768 ? true : false
      }
      // 网站icon
      var link = document.createElement('link');
      link.id = 'myFavicon';
      link.rel = 'icon';
      link.href = this.baseURL + web_icon; // 新图标URL
      document.getElementsByTagName('head')[0].appendChild(link);
      // 网站标题
      document.title = web_title;
    },
    // 分享
    getShareInfo (web_config) {
      const { share_img, share_title, share_intro } = web_config
      //获取url链接（如果有#需要这么获取）
      // var url = encodeURIComponent(window.location.href.split("#")[0]);
      const type = encodeURI(window.location.href.split("#")[0]) === 'https://web.iceriverbj.com/' ? 'web' : 'web-test';
      var url = encodeURI(window.location);
      //获取url链接（如果没有#需要这么获取）// var url = encodeURIComponent(window.location.href);
      axios({
        method: 'get',
        url: `https://${type}.iceriverbj.com/comon/wxshare.php`,
        params: { url }
      }).then(res => {
        const { timestamp, nonceStr, signature, rawString, appId } = res.data
        wx.config({
          debug: false,
          // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
          appId: 'wxd82f9e8be06a2df8',
          // 必填，公众号的唯一标识
          timestamp: parseInt(timestamp),
          // 必填，生成签名的时间戳
          nonceStr,
          rawString,
          // 必填，生成签名的随机串
          signature,
          // 必填，签名
          jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"]
          // 必填，需要使用的 JS 接口列表
        });
        wx.ready(() => {
          // 分享标题、内容、图片
          var shareData = { title: share_title, desc: share_intro, link: window.location.href, imgUrl: this.baseURL + share_img };
          //自定义“分享给朋友”及“分享到QQ”按钮的分享内容
          wx.updateAppMessageShareData(shareData);
          //自定义“分享到朋友圈”及“分享到 QQ 空间”按钮的分享内容（1.4.0）
          wx.updateTimelineShareData(shareData);
        });
        //错误了会走 这里
        wx.error(function (res) { console.log("微信分享错误信息", res) })
      })
    }

  },
}

</script>


<style lang="scss">
// html {
//   font-size: 23.36px;
// }
body,
html {
  width: 100%;
  height: 100%;
  overflow: auto;
}
body {
  padding: 0;
  margin: 0;
  font: 14px tahoma, arial, \5b8b\4f53;
  // font-size: 100%;
  // font-size: 100%;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
blockquote,
dl,
dt,
dd,
ul,
ol,
li,
pre,
form,
fieldset,
legend,
button,
input,
textarea,
th,
td {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
a {
  text-decoration: none;
}
:root {
  --bg: #28469c;
  --shadow: rgba(29, 80, 162, 0.3);
}
#app {
  font-family: Microsoft YaHei, 黑体, Arial, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
  transform: translate3d(0, 0, 0);
}
.app-window {
  width: 100px;
  height: 100px;
  background: #fff;
  border-radius: 8px;
  padding: 8px;
  position: fixed;
  left: 10px;
  top: 150px;
  z-index: 1000;
  .app-close {
    position: absolute;
    right: 9px;
    top: 12px;
    font-size: 14px;
    cursor: pointer;
    z-index: 10000;
    color: #333333;
  }
}
</style>
