import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ELEMENT from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import '@/permission' // permission control
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
/* swiper */
import 'swiper/dist/css/swiper.min.css';
import FastClick from 'fastclick'
FastClick.attach(document.body);
let Base64 = require('js-base64').Base64
localStorage.setItem('add', 1)
Vue.config.productionTip = false
Vue.use(ELEMENT, { size: 'small' })
Vue.prototype.$bus = new Vue()
Vue.prototype.$Base64 = Base64
Vue.prototype.$isPC = window.screen.width > 768 ? true : false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
